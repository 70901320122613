<template>
  <div class="row">
    <div class="col-12 col-lg-10 c g">
      <div class="row">
          <div class="col-12 col-lg-4 g" v-for="device in devices" :key="device._id">
              <div class="card border" style="border-radius: 20px;">
                  <div class="card-body ddd border shadow" @click="gotochat(device._id)" 
                              v-if="device.status == 1" style="border-radius: 20px">
                      <div class="col-12 text-center g" v-if="device.info.image">
                          <img :src="device.info.image" style="width: 50%; border-radius: 20px" alt="">
                      </div>
                      <h4 class="text-center g">
                          {{ device.title }}
                      </h4>
                      <div class="col-12 text-center g">
                          <span
                          class="badge bg-warning"
                          v-if="device.status == 0"
                          >
                          <i class="fa fa-clock-o"></i>
                          لم يتصل بعد
                          </span>
                          <span
                          class="badge bg-success"
                          v-if="device.status == 1"
                          >
                          <i class="fa fa-check"></i>
                          متصل الآن
                          </span>
                          <span class="badge bg-danger" v-if="device.status == 2">
                          <i class="fa fa-times"></i>
                          قُطع الاتصال
                          </span>
                          <span class="badge bg-danger" v-if="device.status == 3">
                          <i class="fa fa-times"></i>
                          الرقم جديد!ّ ربما يتم حظره اذا تم الارسال منه
                          </span></div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-12" v-if="!user.pers || user.pers.includes('*')">
      <strong class="text-danger">*هذه احصائيات للنظام، وبالتالي جميع الاحصائيات تًحسب والنظام مفتوح، ولا يتم احتساب اي احصائيات والنظام مغلق.</strong>
    </div>
    <div class="col-12 g" v-if="!user.pers || user.pers.includes('*')">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="card">
            <div class="card-header text-center">
              <h1>
                {{ stats.today_outcome_messages ? stats.today_outcome_messages : '---' }}
              </h1>
            </div>
            <div class="card-body">
              <i class="fa fa-paper-plane"></i>
              الرسائل المرسلة اليوم
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card">
            <div class="card-header text-center">
              <h1>
                {{ stats.today_messages ? stats.today_messages : '---' }}
              </h1>
            </div>
            <div class="card-body">
              <i class="fa fa-comment"></i>
              الرسائل المستلمة اليوم
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card">
            <div class="card-header text-center">
              <h1>
                {{ stats.today_chats ? stats.today_chats : '---' }}
              </h1>
            </div>
            <div class="card-body">
              <i class="fa fa-comments"></i>
              المحادثات الغير مقروءة المفتوحة اليوم
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 g" v-if="!user.pers || user.pers.includes('*')">
      <div class="card">
        <div class="card-header">
          <h5>
            <i class="fa fa-users"></i>
            احصائيات المشرفين لليوم
          </h5>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-striped">
              <thead>
                <th>
                  المشرف
                </th>
                <th>
                  المحادثات الغير مقروءة المفتوحة اليوم
                </th>
                <th>
                  الرسائل المرسلة اليوم
                </th>
                <th>
                  آخر رسالة مرسلة
                </th>
              </thead>
              <tbody>
                <tr v-for="(admin, i) in stats.admins" :key="admin.name" :class="admin.today_chats == 0 && admin.today_outcome_messages == 0 ? 'bg-light-danger' : ''">
                  <td>
                    {{ admins_names[admin.name] }}
                    <template v-if="i == 0 && (admin.today_chats > 0 || admin.today_outcome_messages > 0)">
                      <button class="btn btn-sm text-warning shadow border" style="padding: 2px">
                        <img :src="require('@/assets/images/best.png')" width="24" alt="">
                        الأفضل لليوم
                      </button>
                    </template>
                    <span v-if="admin.today_chats == 0 && admin.today_outcome_messages == 0" class=" text-warning shadow border" style="padding: 2px">
                      <img :src="require('@/assets/images/sleep.png')" width="24" alt="">
                    </span>
                  </td>
                  <td>
                    {{ admin.today_chats }}
                  </td>
                  <td>
                    {{ admin.today_outcome_messages }} <a href="javascript:;" v-b-modal.modal-1 @click="getAdminMessages(admin.name)" class="fa fa-eye"></a>
                  </td>
                  <td>
                    {{ timeSince(new Date(admin.last_outcome_message)) == 'NaN ثانية' ? '--' : 'منذ ' + timeSince(new Date(admin.last_outcome_message)) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" title="الرسائل المرسلة من المشرف" size="lg" hide-footer>
      <div class="alert alert-info g text-center">
        الرسائل هنا لا تكون بشكل لحظي، تتأخر حوالي دقيقة عن الوقت الفعلي للظهور هنا.
      </div>
      <div class="col-12 table-responsive">
        <table class="table table-sm table-hover table-striped">
          <thead>
            <th>
              العميل
            </th>
            <th>
               النوع
            </th>
            <th>
              الرسالة
            </th>
            <th>
              التاريخ
            </th>
          </thead>
          <tbody>
            <tr v-for="(m,i) in messages" :key="i">
              <td>
                {{ m.to ? m.to.split('@')[0] : m.to }}
              </td>
              <td>
                {{ m.message_type }}
              </td>
              <td>
                {{ m.message_type == 'text' ? m.body : '--' }}
              </td>
              <td>
                {{ m.date ? m.date.replace('T', ' ').split(".")[0] : '' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data(){
    return {
      user: JSON.parse(localStorage.getItem('user')),
      loading: false,
      devices: [],
      inertValDevices: null,
      current_device: {},
      qrcodeaftergenerate: "",
      stats: {
        admins: []
      },
      admins_names: {
        "main-user": 'المسؤول',
        'ai': 'دربكش AI'
      },
      messages: [],
      admins_ids: {
        "main-user": 'المسؤول',
        'ai': 'دربكش AI'
      }
    }
  },
  created(){
    var g = this;
    this.loading = true
    g.getDevices()
    g.admins_ids['main-user'] = g.user._id;
    $.post(api + '/user/admins/list', {
          jwt: g.user.jwt
      }).then(function(r){
          if(r.status != 100){
          }else{
              r.response.forEach(function(a){
                g.admins_names[a._id] = a.name;
              })
          }
      }).fail(function(){
          alert("حدث مشكلة في الاتصال")
      })
    $.post(api + '/user/devices/list', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.devices = r.response
            }
        }).fail(function(){
            g.loading = false
        })
  },
  methods: {
    getAdminMessages(name){
      var g = this;
      g.messages = [];
      $.post(api + '/user/devices/messages', {
            jwt: this.user.jwt,
            id: name.replace("main-user", g.user._id),
            date: new Date().toISOString().split('T')[0],
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.messages = r.response
            }
        }).fail(function(){
            g.loading = false
        })
    },
    timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " سنة";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " شهر";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " يوم";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " ساعة";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " دقيقة";
        }
        return Math.floor(seconds) + " ثانية";
        },
    gotochat(id){
      window.location = '/chat#' + id
    },
    getDevices(){
    var g = this;
    $.post(api + '/user/devices/stats', {
            jwt: this.user.jwt,
            date: new Date().toISOString().split('T')[0],
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.stats = r.response
            }
        }).fail(function(){
            g.loading = false
        })
    },
  }
}
</script>

<style>
.ddd:hover{
    background: #eee;
    cursor: pointer;
}
</style>